<template>
  <div class="w-full mt-4">
    <div class="mb-4 flex flex-row justify-between space-x-2">
      <n-button circle @click="refreshData()">
        <template #icon>
          <n-icon>
            <RefreshFilled />
          </n-icon>
        </template>
      </n-button>
      <div class="inline-flex items-center space-x-1 w-44 sm:space-x-2 sm:w-64">
        <n-text class=" text-nowrap">
          {{ t('commons.category') }}
        </n-text>
        <n-select
          v-model:value="categorySelectValue"
          size="small"
          class="w-28"
          :placeholder="t('tips.pleaseSelect')"
          :options="categories.map((item) => ({ label: item.name, value: item.id }))"
          @update:value="handleGetPromptByCategoryId"
        />
      </div>
    </div>
    <n-data-table
      :scroll-x="400"
      size="small"
      :columns="columns"
      :data="data"
      :bordered="true"
      :pagination="{
        pageSize: 20,
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { RefreshFilled } from '@vicons/material';
import { DataTableColumns, NButton, NEllipsis, NIcon, SelectOption } from 'naive-ui';
import { h, ref } from 'vue';

import { getActivePromptApi, getAllPromptCategoryApi, getPromptByCategoryIdApi } from '@/api/prompt';
import { i18n } from '@/i18n';
import { ChoosedPromptInfo } from '@/types/custom';
import { PromptCategoryRead, PromptRead } from '@/types/schema';
import { Message } from '@/utils/tips';

const t = i18n.global.t as any;

const emits = defineEmits<{
  (e: 'input', choosedPromptInfo: ChoosedPromptInfo): void;
}>();

const categorySelectValue = ref(null);

const categories = ref<Array<PromptCategoryRead>>([]);
const data = ref<Array<PromptRead>>([]);

const columns: DataTableColumns<PromptRead> = [
  {
    title: '#',
    key: 'id',
    sorter: 'default',
    width: 10,
  },
  {
    title: t('commons.title'),
    key: 'title',
    sorter: 'default',
    width: 120,
    render: (row) => {
      return h(
        NEllipsis,
        { style: 'max-width: 120px' },
        { default: () => (row.title ? row.title : t('commons.empty')) }
      );
    },
  },
  {
    title: t('commons.content'),
    key: 'content',
    render: (row) => {
      return h(
        NEllipsis,
        { style: 'max-width: 260px' },
        { default: () => (row.content ? row.content : t('commons.empty')) }
      );
    },
  },
  {
    title: t('commons.category'),
    key: 'category',
    width: 80,
    render(row) {
      return row.category.name ? row.category.name : t('commons.empty');
    },
  },
  {
    title: t('commons.actions'),
    key: 'actions',
    fixed: 'right',
    width: '60px',
    render(row) {
      return h(
        NButton,
        { size: 'small', type: 'primary', onClick: () => onChoosedPrompt(row) },
        { default: () => t('commons.select') }
      );
    },
  },
];

const refreshData = () => {
  categorySelectValue.value = null;
  getActivePromptApi().then((res) => {
    data.value = res.data;
    Message.success(t('tips.refreshed'));
  });
  getAllPromptCategoryApi().then((res) => {
    categories.value = res.data;
    Message.success(t('tips.refreshed'));
  });
};

const handleGetPromptByCategoryId = (value: number, _option: SelectOption) => {
  getPromptByCategoryIdApi(value).then((res) => {
    data.value = res.data;
  });
};

const onChoosedPrompt = (row: PromptRead) => {
  Message.info(t('tips.alreadyFilledSelfModification'));
  emits('input', {
    id: row.id,
    title: row.title,
    content: row.content
  } as ChoosedPromptInfo);
};

getActivePromptApi().then((res) => {
  data.value = res.data;
});
getAllPromptCategoryApi().then((res) => {
  categories.value = res.data;
});
</script>
