import { MdMore } from '@vicons/ionicons4';
import { NButton, NDropdown, NIcon, NInput } from 'naive-ui';
import { h } from 'vue';

import { i18n } from '@/i18n';
import { ChoosedPromptInfo, NewConversationInfo } from '@/types/custom';
import { BaseConversationSchema } from '@/types/schema';
import { Dialog, Message } from '@/utils/tips';
import NewConversationForm from '@/views/conversation/components/NewConversationForm.vue';
import PromptStoreForm from '@/views/conversation/components/PromptStoreForm.vue';

const t = i18n.global.t as any;

export const dropdownRenderer = (
  conversation: BaseConversationSchema,
  handleDeleteConversation: (conversation_id?: string) => void,
  handleChangeConversationTitle: (conversation_id?: string) => void
) =>
  h(
    NDropdown,
    {
      trigger: 'hover',
      options: [
        {
          label: t('commons.delete'),
          key: 'delete',
          props: {
            onClick: () => handleDeleteConversation(conversation.conversation_id || undefined),
          },
        },
        {
          label: t('commons.rename'),
          key: 'rename',
          props: {
            onClick: () => handleChangeConversationTitle(conversation.conversation_id || undefined),
          },
        },
      ],
    },
    {
      default: () =>
        h(
          NButton,
          {
            size: 'small',
            quaternary: true,
            circle: true,
          },
          { default: () => h(NIcon, null, { default: () => h(MdMore) }) }
        ),
    }
  );

export const popupInputDialog = (
  title: string,
  placeholders: string[],
  count: number,
  callback: (inputs: string[]) => Promise<any>,
  success: () => void,
  fail: () => void
) => {
  const inputs: string[] = new Array(count).fill('');
  const d = Dialog.info({
    title: title,
    positiveText: t('commons.confirm'),
    negativeText: t('commons.cancel'),
    content: () =>
      placeholders.map((placeholder, index) =>
        h(NInput, {
          onInput: (e) => (inputs[index] = e),
          autofocus: index === 0,
          placeholder: placeholder,
          style: { margin: '10px 0px' },
        })
      ),
    onPositiveClick() {
      d.loading = true;
      return new Promise((resolve) => {
        callback(inputs)
          .then(() => {
            success();
            resolve(true);
          })
          .catch(() => {
            fail();
            resolve(true);
          })
          .finally(() => {
            d.loading = false;
          });
      });
    },
  });
};

export const popupChangeConversationTitleDialog = (
  conversation_id: string,
  callback: (title: string[]) => Promise<any>,
  success: () => void,
  fail: () => void
) => {
  popupInputDialog(t('commons.rename'), [t('tips.rename')], 1, callback, success, fail);
};

export const popupResetUserPasswordDialog = (
  callback: (password: string[]) => Promise<any>,
  success: () => void,
  fail: () => void
) => {
  popupInputDialog(
    t('commons.resetPassword'),
    [t('tips.inputOldPassword'), t('tips.inputNewPassword')],
    2,
    callback,
    success,
    fail
  );
};

export const popupNewConversationDialog = (callback: (newConversationInfo: NewConversationInfo) => Promise<void>) => {
  let input = null as NewConversationInfo | null;
  const d = Dialog.info({
    title: t('commons.newConversation'),
    positiveText: t('commons.confirm'),
    negativeText: t('commons.cancel'),
    content: () =>
      h(NewConversationForm, {
        onInput: (newConversationInfo: NewConversationInfo) => {
          input = newConversationInfo;
        },
      }),
    style: { width: '500px' },
    onPositiveClick() {
      d.loading = true;
      return new Promise((resolve, _reject) => {
        if (input === null) {
          resolve(false);
        }
        if (input?.model === null) {
          Message.error(t('tips.modelRequired'));
          resolve(false);
        }
        callback(input!)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(true);
          })
          .finally(() => {
            d.loading = false;
          });
      });
    },
  });
};

export const popupPromptStoreDialog = (callback: (choosedPromptInfo: ChoosedPromptInfo) => Promise<void>) => {
  let input = null as ChoosedPromptInfo | null;
  const d = Dialog.create({
    title: t('commons.promptStore'),
    positiveText: t('commons.cancel'),
    content: () =>
      h(PromptStoreForm, {
        onInput: (choosedPromptInfo: ChoosedPromptInfo) => {
          d.loading = true;
          input = choosedPromptInfo;
          return new Promise((resolve, _reject) => {
            if (input === null) {
              resolve(false);
            }
            callback(input!)
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                resolve(true);
              })
              .finally(() => {
                d.loading = false;
                d.destroy();
              });
          });
        },
      }),
    style: { width: '80%' },
  });
};
