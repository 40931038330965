import axios from 'axios';

import { PromptCategoryCreate, PromptCategoryRead, PromptCreate, PromptRead } from '@/types/schema';

import ApiUrl from './url';

export function getActivePromptApi() {
  return axios.get<PromptRead[]>(ApiUrl.PromptList);
}

export function getPromptByCategoryIdApi(promptCategoryId: number) {
  return axios.get<PromptRead[]>(ApiUrl.PromptList + `/c${promptCategoryId}`);
}

export function getAllPromptApi() {
  return axios.get<PromptRead[]>(ApiUrl.PromptAllList);
}

export function getAllPromptCategoryApi() {
  return axios.get<PromptCategoryRead[]>(ApiUrl.PromptCategoryList);
}

export function promptAddApi(prompt: PromptCreate) {
  return axios.post<PromptRead>(ApiUrl.PromptAdd, prompt);
}

export function promptCategoryAddApi(promptCategory: PromptCategoryCreate) {
  return axios.post<PromptCategoryRead>(ApiUrl.PromptCategoryAdd, promptCategory);
}

export function updatePromptByIdApi(promptId: number, promptUpdate: Partial<PromptCreate>) {
  return axios.patch<PromptRead>(ApiUrl.PromptList + `/${promptId}`, promptUpdate);
}

export function updatePromptCategoryByIdApi(
  promptCategoryId: number,
  promptCategoryUpdate: Partial<PromptCategoryCreate>
) {
  return axios.patch<PromptCategoryRead>(ApiUrl.PromptCategoryList + `/${promptCategoryId}`, promptCategoryUpdate);
}

export function deletePromptApi(promptId: number) {
  return axios.delete(ApiUrl.PromptList + `/${promptId}`);
}

export function deletePromptCategoryApi(promptCategoryId: number) {
  return axios.delete(ApiUrl.PromptCategoryList + `/${promptCategoryId}`);
}
