import DOMPurify from 'dompurify';

import { i18n } from '@/i18n';
import { allChatModelNames } from '@/types/json_schema';
import {
  BaseChatMessage,
  BaseConversationHistory,
  OpenaiApiChatMessageTextContent,
  OpenaiApiChatModels,
} from '@/types/schema';

const t = i18n.global.t as any;

export const chatModelColorMap: Record<string, string> = {
  gpt_3_5_turbo: 'green',
  gpt_3_5_turbo_16k: 'darkgreen',
  gpt_4: 'purple',
  gpt_4_32k: 'darkpurple',
  gpt_4_turbo: 'blue',
};

export const getChatModelColor = (model_name: OpenaiApiChatModels | string | null) => {
  if (model_name && chatModelColorMap[model_name]) return chatModelColorMap[model_name];
  else return 'black';
};

export const getChatModelIconStyle = (_model_name: OpenaiApiChatModels | string | null) => {
  return 'default';
};

export const getChatModelNameTrans = (model_name: OpenaiApiChatModels | string | null) => {
  if (model_name == null) return t('commons.unknown');
  if (allChatModelNames.includes(model_name)) return t(`models.${model_name}`);
  else return `${t('commons.unknown')}(${model_name})`;
  // else return model_name;
};

export const getCountTrans = (count: number | undefined | null): string => {
  if (count == undefined || count == null) return t('commons.unlimited');
  return count == -1 ? t('commons.unlimited') : `${count}`;
};

export const getContentRawText = (message: BaseChatMessage | null): string => {
  if (!message || !message.content) return '';
  if (typeof message.content == 'string') return message.content;
  else if (message.content.content_type == 'text') {
    const content = message.content as OpenaiApiChatMessageTextContent;
    return content.text || '';
  } else {
    return `${message.content}`;
  }
};

export function getMessageListFromHistory(
  convHistory: BaseConversationHistory | undefined | null,
  lastNode: string | null = null
): BaseChatMessage[] {
  const result: BaseChatMessage[] = [];
  if (!convHistory) return result;
  let x = lastNode || convHistory.current_node || (undefined as any);
  while (x != undefined) {
    const message = convHistory.mapping[x];
    if (message && message.content != undefined) {
      result.push(message);
      x = message.parent;
    } else {
      break;
    }
  }
  result.reverse();
  return result;
}

// 用于按照连续消息分组，end_turn为true时截断
export function mergeContinuousMessages(messages: BaseChatMessage[]): BaseChatMessage[][] {
  const result = [] as BaseChatMessage[][];
  let currentMessageList = [] as BaseChatMessage[];
  for (const message of messages) {
    // TODO: API 暂不支持连续对话合并
    if (currentMessageList.length > 0) {
      result.push(currentMessageList);
      currentMessageList = [];
    }
    result.push([message]);
  }
  if (currentMessageList.length > 0) {
    result.push(currentMessageList);
  }
  return result;
}

// 对于一段连续消息中的消息按照功能性来分组
export function splitMessagesInGroup(messages: BaseChatMessage[]): BaseChatMessage[][] {
  const result = [] as BaseChatMessage[][];
  let currentMessageList = [] as BaseChatMessage[];

  for (const message of messages) {
    // TODO: API 暂不支持连续对话合并
    if (currentMessageList.length > 0) {
      result.push(currentMessageList);
      currentMessageList = [];
    }
    result.push([message]);
  }
  if (currentMessageList.length > 0) {
    result.push(currentMessageList);
  }
  return result;
}

export function getTextMessageContent(messages: BaseChatMessage[]) {
  let result = '';
  // 遍历 props.messages
  // 如果 message.content.content_type == 'text' 则加入 result，其它跳过
  // 对于 GPT-4-browsing 的引用，转换为 span
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i] as BaseChatMessage;
    if (!message || !message.content) continue;
    else if (typeof message.content == 'string') result += message.content;
    else if (message.content.content_type == 'text') {
      const text = getContentRawText(message);
      result += text;
    }
  }
  // console.log('text display result', result);
  result = replaceMathDelimiters(result);
  return result;
}

export function replaceMathDelimiters(input: string) {
  let output = '';
  let pos = 0;
  let isCodeBlock = false,
    isCodeInline = false;
  const nextChar = (n: number) => {
    if (pos + n >= input.length) return '';
    else return input.charAt(pos + n);
  };

  while (pos < input.length) {
    const c = input.at(pos);
    if (c === '\\' && !isCodeBlock && !isCodeInline) {
      if (nextChar(1) === '(' || nextChar(1) === '[') {
        const isInline = nextChar(1) === '(';
        const endMarker = isInline ? '\\)' : '\\]';
        const endPos = input.indexOf(endMarker, pos + 2);
        if (endPos >= 0) {
          output += isInline ? '$' : '\n$$\n';
          output += input.substring(pos + 2, endPos).trim();
          output += isInline ? '$' : '\n$$\n';
          pos = endPos + endMarker.length;
          continue;
        }
      }
    } else if (c === '`') {
      const isCodeBlockDelimiter = nextChar(1) === '`' && nextChar(2) === '`';
      if (isCodeBlockDelimiter) {
        if (isCodeBlock) {
          isCodeBlock = false;
        } else if (isCodeInline) {
          isCodeInline = false;
          isCodeBlock = true;
        } else {
          isCodeBlock = true;
        }
        output += '```';
        pos += 3;
        continue;
      } else {
        if (isCodeInline) {
          isCodeInline = false;
        } else {
          isCodeInline = true;
        }
      }
    }
    output += input.charAt(pos);
    pos++;
  }
  // return output;
  return output.replace(/\n\n\n+/g, '\n\n');
}

export function dompurifyRenderedHtml(html: string) {
  return DOMPurify.sanitize(html, { ALLOW_UNKNOWN_PROTOCOLS: true });
}
