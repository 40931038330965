import { useOsTheme } from 'naive-ui';
import { defineStore } from 'pinia';

import { AppState, Preference } from '../types';
import { useStorage } from '@vueuse/core';

import { setLocale } from '@/i18n';
import { ChatSourceTypes } from '@/types/schema';
import { themeRef } from '@/utils/tips';

const osThemeRef = useOsTheme();

const useAppStore = defineStore('app', {
  state: (): AppState => ({
    theme: useStorage('theme', osThemeRef.value),
    language: useStorage('language', 'zh-CN'),
    preference: useStorage<Preference>('preference', {
      sendKey: 'Enter',
      renderUserMessageInMd: false,
      codeAutoWrap: false,
    }),
    lastSelectedSource: useStorage<ChatSourceTypes>('lastSelectedSource', null),
    lastSelectedModel: useStorage<string | null>('lastSelectedModel', null),
  }),
  getters: {},
  actions: {
    // 切换主题
    toggleTheme() {
      switch (this.theme) {
        case 'auto':
          this.theme = 'dark';
          break;
        case 'dark':
          this.theme = 'light';
          break;
        case 'light':
          this.theme = 'auto';
          break;
      }
      themeRef.value = this.theme;
    },
    setLanguage(lang: string) {
      this.language = lang;
      setLocale(lang);
    },
  },
});

export default useAppStore;
