import modelDefinitions from './json/model_definitions.json';
import jsonSchemas from './json/schemas.json';
import { OpenaiApiChatModels } from './schema';

export const jsonApiSourceSettingSchema = jsonSchemas.OpenaiApiSourceSettingSchema;

export const jsonConfigModelSchema = jsonSchemas.ConfigModel;
export const jsonCredentialsModelSchema = jsonSchemas.CredentialsModel;

export const openaiApiChatModelNames = modelDefinitions.openai_api as OpenaiApiChatModels[];
export const allChatModelNames = [...openaiApiChatModelNames] as string[];