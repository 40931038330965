import type { GlobalThemeOverrides } from 'naive-ui';
import { darkTheme, useOsTheme } from 'naive-ui';
import { computed, watch } from 'vue';

import { useAppStore } from '@/store';

export function useTheme() {
  const appStore = useAppStore();

  const osTheme = useOsTheme();

  const isDark = computed(() => {
    if (appStore.theme === 'auto') return osTheme.value === 'dark';
    else return appStore.theme === 'dark';
  });

  const theme = computed(() => {
    return isDark.value ? darkTheme : null;
  });

  const themeOverrides = computed<GlobalThemeOverrides>(() => {
    if (isDark.value) {
      return {
        common: {
          primaryColor: '#51a8dd',
          primaryColorHover: '#7db9de',
          primaryColorPressed: '#005caf',
          primaryColorSuppl: '#7db9de',
        },
      };
    }
    return {
      common: {
        primaryColor: '#51a8dd',
        primaryColorHover: '#7db9de',
        primaryColorPressed: '#005caf',
        primaryColorSuppl: '#7db9de',
      },
    };
  });

  watch(
    () => isDark.value,
    (dark) => {
      if (dark) {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#000000');
        document.documentElement.classList.add('dark');
      } else {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#ffffff');
        document.documentElement.classList.remove('dark');
      }
    },
    { immediate: true }
  );

  return { theme, themeOverrides };
}
