<template>
  <n-config-provider
    class="h-full"
    :locale="locale"
    :theme="theme"
    :theme-overrides="themeOverrides"
  >
    <n-global-style />
    <div
      class="flex justify-center h-full dark:bg-[#24272e] transition-all"
      :class="[isMobile ? 'p-0' : 'p-10']"
    >
      <div
        class="flex-auto h-full overflow-hidden max-w-5xl"
        :class="getMobileClass"
      >
        <n-layout class="transition h-full">
          <PageHeader v-if="userStore.user" />
          <div
            class="h-full"
            style="height: calc(100% - var(--header-height))"
          >
            <router-view />
          </div>
        </n-layout>
      </div>
    </div>
  </n-config-provider>
</template>

<script setup lang="ts">
import { enUS, zhCN } from 'naive-ui';
import { computed } from 'vue';

import PageHeader from './components/PageHeader.vue';
import { useBasicLayout } from './hooks/useBasicLayout';
import { useTheme } from './hooks/useTheme';
import { useAppStore, useUserStore } from './store';

const { isMobile } = useBasicLayout();

const { theme, themeOverrides } = useTheme();
const userStore = useUserStore();
const appStore = useAppStore();

const getMobileClass = computed(() => {
  if (isMobile.value) return ['rounded-none', 'shadow-none'];
  return ['border', 'rounded-2xl', 'shadow-2xl', 'dark:border-neutral-500'];
});

const locale = computed(() => {
  if (appStore.language == 'zh-CN') return zhCN;
  else return enUS;
});
</script>
